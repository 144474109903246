import { Box } from "@mui/material";

import background from "../../static/images/Achtergrond.webp"

const Background = ({ blurred }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: "black",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        maxHeight: "100vh",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        zIndex: "-10000",
        filter: blurred ? "blur(20px)" : "None"
      }}
    >
    </Box>
  )
}

export default Background;