import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from 'react-redux';


import Main from "./pages/Main";
import AnalyticsLogin from "./pages/AnalyticsLogin"
import Analytics from "./pages/Analytics"

const AppRouter: React.FC = () => {

  const state = useSelector((state: any) => state)

  const hasToken = () => state.token !== null && state.token !== undefined && state.token.raw !== undefined;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/insights" element={hasToken() ? <Analytics /> : <AnalyticsLogin />} />
        <Route path="*" element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
