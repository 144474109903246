import { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  ListItemIcon,
  MenuItem,
  Divider,
  Menu,
  IconButton,
  Avatar
} from "@mui/material"

import { useDispatch } from 'react-redux';

import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { logOut } from '../../actions/tokenActions';

import Logo from "../../static/images/logo.svg";



const Header = () => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const AccountMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor: "#2AEE7D",
            borderRadius: "3px",
            color: "#0E2150",
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#2AEE7D',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <Divider sx={{ borderColor: "#0E2150" }} /> */}
        <MenuItem onClick={() => dispatch(logOut())}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    )
  }

  return (
    <Box sx={{ flexGrow: 1, background: "#0E2150", padding: 5, pb: 0, pt: 4, fill: "#2AEE7D" }}>
      <Grid container>

        <Grid item sm={6} >
          <Box sx={{
            width: "13em",
            height: "2.5em",
            filter: "invert(76%) sepia(93%) saturate(425%) hue-rotate(76deg) brightness(97%) contrast(92%)"
          }}

          >
            <object data={Logo} fill="white" />
          </Box>
        </Grid>

        <Grid item sm={5} sx={{ textAlign: "right", color: "#2AEE7D" }}>
          <Typography variant="h4" fontWeight={100} fontFamily="Space Grotesk" textAlign="right" >
            INSIGHTS
          </Typography>
        </Grid>

        <Grid item sm={1}>
          <IconButton onClick={handleClick} sx={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "0",
          }}>
            <Avatar
              alt="Unavailable"
              sx={{
                background: "#2AEE7D",
                color: "#0E2150"
              }}

            ></Avatar>
          </IconButton>

        </Grid>
      </Grid>
      <AccountMenu />
    </Box >
  )
}

export default Header;