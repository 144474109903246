import { Grid } from "@mui/material"

import BarChart from "./BarChart"
import PieChart from "./PieChart";

const Charts = () => {
  return (
    <Grid container spacing={0}>
      <Grid item sm={12} md={8}>
        <BarChart />
      </Grid>
      <Grid item sm={12} md={4}>
        <PieChart />
      </Grid>
    </Grid>
  )
}

export default Charts;