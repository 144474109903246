import { IconButton, Box, Grid, Button } from "@mui/material";

import { GB, NL } from 'country-flag-icons/react/3x2'

const MainMenu = ({ lang, onSetDifficulty, onChangeLanguage }) => {
  const Title = () => {
    return (
      <Box
        sx={{
          font: "normal normal bold 143px/167.3px Raleway",
          textAlign: "center",
          textTransform: "uppercase",
          textShadow: "0px 3px 6px #00000029",
          height: "231.6px",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "170.2px",
        }}
      >
        Quiz
      </Box>
    )
  }

  const LanguageSelect = () => {
    return (
      <Grid item xs={12} spacing={30} marginTop={"600px"}>
        <Grid container justifyContent="center">
          <IconButton onClick={() => onChangeLanguage("NL")}>
            <Grid container justifyContent="center" sx={{
              font: "normal normal bold 48.6px/57.2px Raleway;",
              color: "white"
            }}>
              <Grid item xs={16}>NED</Grid>
              <Grid sx={{ borderBottom: lang == "NL" ? "white 10px solid" : "" }} item><NL title="Nederlands" height="85.8px" /></Grid>
            </Grid>
          </IconButton>

          <IconButton onClick={() => onChangeLanguage("EN")}>
            <Grid container justifyContent="center" sx={{
              font: "normal normal bold 48.6px/57.2px Raleway;",
              color: "white"
            }}>
              <Grid item xs={16}>ENG</Grid>
              <Grid sx={{ borderBottom: lang == "EN" ? "white 10px solid" : "" }} item><GB title="English" height="85.8px" /></Grid>
            </Grid>
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const MenuText = () => {
    return (
      <Grid item xs={12} marginTop={10}>
        <Grid container justifyContent="center">
          <Grid item xs={10} sx={{
            font: "normal normal 600 37.2px/42.9px Poppins;",
            textAlign: "center"
          }}>
            {lang === "NL" ?
              "Wat weet jij over de natuur langs de grachten? Test je kennis!"
              : "What do you know about the nature along the canals? Test your knowledge!"}
          </Grid>
        </Grid>

      </Grid>
    )
  }

  const DifficultyButtons = () => {
    return (
      <Grid xs={12} marginTop="475px">
        <Grid container justifyContent="center">
          <Grid item xs={8}>
            <Button sx={{
              color: "black",
              background: "white",
              borderRadius: "34px",
              height: "64px",
              font: "normal normal bold 36px/42px Raleway",
              letterSpacing: "0px",
              width: "191px",
              height: "117px",
            }}
              onClick={() => onSetDifficulty("EASY")}
            >
              {lang === "NL" ? "Makkelijk" : "Easy"}
            </Button>

            <Button sx={{
              float: "right",
              color: "black",
              background: "white",
              borderRadius: "34px",
              height: "64px",
              font: "normal normal bold 36px/42px Raleway",
              letterSpacing: "0px",
              width: "191px",
              height: "117px",
            }}
              onClick={() => onSetDifficulty("HARD")}
            >
              {lang === "NL" ? "Moeilijk" : "Difficult"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const StartButton = () => {
    return (
      <Grid xs={12} marginTop="100px">
        <Grid container justifyContent="center">

          <Button sx={{
            color: "black",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "34px",
            height: "114.4px",
            width: "273.13px",
            textTransform: "uppercase",
            font: "normal normal bold 45.8px/52.9px Raleway;",
            letterSpacing: "0px",
          }}
            onClick={() => onSetDifficulty("HARD")}
          >
            {lang === "NL" ? "Start" : "Start"}
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Title />
      <Grid container justifyContent="center">
        <MenuText />
        <LanguageSelect />

        <StartButton />
      </Grid>
    </div>
  )
}

export default MainMenu;