import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import { logIn } from '../../actions/tokenActions'
import { Link } from '@mui/material';

const LoginForm = () => {

  const dispatch = useDispatch()

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');

    dispatch(logIn(username, password));
  };

  return (
    <div style={{
      marginTop: 8,
      borderRadius: "10px",
      background: "#EEE7D4",
      color: "#0E2150",
      fontFamily: "Space Grotesk !important",
      maxWidth: 600,
      padding: "30px"
    }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent: 'center',
          fontFamily: "Space Grotesk !important",

        }}
      >
        <Typography fontWeight={300} color="#0E2150" fontFamily={"Space Grotesk"} variant="h5" style={{ marginBottom: 30, textAlign: "center" }}>
          <b>Hey!</b> Vul je gegevens in om in te loggen
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            color="primary"
            margin="normal"
            required
            fullWidth
            fontFamily="Space Grotesk"
            id="username"
            label="Gebruikersnaam"
            name="username"
            autoComplete="username"
            autoFocus
            InputLabelProps={{
              style: {
                color: "#0E2150",
                borderColor: "#0E2150",
                fontFamily: "Space Grotesk"
              }
            }}
            InputProps={{
              style: {
                fontFamily: "Space Grotesk",
                color: "#0E2150"
              }
            }}
          />
          <TextField
            color="primary"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Wachtwoord"
            type="password"
            id="password"
            autoComplete="current-password"
            InputLabelProps={{
              style: {
                color: "#0E2150",
                borderColor: "#0E2150",
                fontFamily: "Space Grotesk !important"
              }
            }}
            InputProps={{
              style: {
                fontFamily: "Space Grotesk",
                color: "#0E2150"
              }
            }}
          />
          <Button
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            sx={{ mt: 3, mb: 2, color: "#2AEE7D" }}

          >
            Log In
          </Button>
        </Box>

        <Typography variant="h6" fontWeight={400} >
          <Link href="mailto:chris.bras@transmark-holdings.com?SUBJECT=Wachtwoord%20Vergeten&body=Hoi Chris,%0d%0DEcht super%20stom%20van%20me, maar ik ben mijn wachtwoord vergeten. Zou je me daarmee kunnen helpen?%0d%0DGroet,">
            <p>Wachtwoord vergeten?</p>

          </Link>
        </Typography>

      </Box>
    </div>
  )
}

export default LoginForm;

