import axios from "axios";
import { logOut } from '../actions/tokenActions';


const protectedAxios = axios.create();

export const initInterceptor = (dispatch: any, store: any) => {
    var prevURL: string | null = null;

    protectedAxios.interceptors.request.use((config) => {
        const state = store.getState();
        const headers = {
            'Authorization': `Bearer ${state.token?.raw?.access_token}`
        }

        config.headers = headers;

        return config;
    })

    protectedAxios.interceptors.response.use(
        (response: any) => response,
        async (error: any) => {

            const reject_ = () => new Promise((resolve, reject) => {
                reject(error);
            });

            const retry_ = () => new Promise((resolve, reject) => {
                axios.request(error.config)
                    .then((resp: any) => resolve(resp))
                    .catch((e: any) => reject(e));
            })

            if (error?.response?.status === 400) {
                console.error(400)
            }

            // Ignore any non 401 errors
            if (error?.response?.status !== 401) {
                return reject_()
            }

            if (error.config.url === '/api/user/token/refresh') {
                dispatch(logOut())
                return reject_();
            }

            /* prevent interceptor from looping. */
            if (error.config.url === prevURL) {
                prevURL = null;
                return reject_();
            }

            prevURL = error.config.url;

            dispatch(logOut());
        }
    )
}

export const getNumberTilesValues = () => {
    return protectedAxios.get("/api/analysis/numbertilevalues")
}

export const getNumberTilesGraphs = () => {
    return protectedAxios.get("/api/analysis/numbertilegraphs")
}

export const getPieChartData = () => {
    return protectedAxios.get("/api/analysis/piechart")
}

export const getQuestionCorrectData = () => {
    return protectedAxios.get("/api/analysis/questions")
}