import React from "react";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'


import "./App.scss";
import AppRouter from "./AppRouter";
import StorageProvider from "./Store"
import ErrorComponent from "./components/ErrorComponent";

import { initInterceptor } from "./util/analyticsApi";


// theme
import ThemeConfig from './theme';

const App: React.FC = () => {

  const { store, persistor } = StorageProvider();

  initInterceptor(store.dispatch, store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeConfig>
          <ErrorComponent />
          <AppRouter />
        </ThemeConfig>
      </PersistGate>
    </Provider>
  );
};

export default App;
