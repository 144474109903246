import QuestionTitleBar from "../../components/Quiz/QuestionTitleBar";
import QuestionDisplay from "../../components/Quiz/QuestionDisplay";
import Background from "../../components/Quiz/Background";
import ResultDisplay from "../../components/Quiz/ResultDisplay";
import QuizData from "../../components/Quiz/Data";
import Images from "../../components/Quiz/Images";
import MainMenu from "../../components/Quiz/MainMenu";
import { useEffect, useState } from "react";

import { createSession, postAnswer, finishSession } from "../../util/api";


const Main = () => {

  const [lang, setLang] = useState("NL")
  const [difficulty, setDifficulty] = useState("HARD")
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [quizStarted, setQuizStarted] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [sessionID, setSessionID] = useState(undefined);
  const [shouldStart, setShouldStart] = useState(false);

  useEffect(() => {
    if (shouldStart) {
      createSession(lang, difficulty).then((response) => {
        setShouldStart(false)
        setSessionID(response.data.id)
        setQuizStarted(true);
      })
    }
  }, [shouldStart])

  const onAnswer = (answer, index) => {

    setShowFeedback(true);

    // check if answer already present
    if (answers.filter((a) => {
      return a.question === currentQuestion
    }).length === 0) {
      const ans = {
        question: currentQuestion,
        correct: answer
      }

      setAnswers([...answers, ans])
      setSelectedIndex(index)

      postAnswer(sessionID, currentQuestion, index, answer);
    }

  }

  const onNext = () => {
    setShowFeedback(false);


    if ((currentQuestion + 1) === QuizData[lang]?.[difficulty]?.length) {
      finishSession(sessionID)
    }

    setCurrentQuestion(currentQuestion + 1)
  }

  const resetQuiz = () => {
    setAnswers([])
    setShowFeedback(false);
    setSessionID(undefined);
    setCurrentQuestion(0)
  }

  const startQuiz = () => {
    resetQuiz();

    setShouldStart(true)
  }

  const handleHome = () => {
    resetQuiz();
    setQuizStarted(false);
  }

  const handleDifficultyToggle = () => {
    if (difficulty === "EASY") {
      handleSetDifficulty("HARD")
    } else {
      handleSetDifficulty("EASY")
    }
  }

  const handleSetDifficulty = (diff) => {
    resetQuiz();
    setDifficulty(diff)
    startQuiz();
  }

  const Quiz = () => {
    return (
      <div>
        {currentQuestion !== QuizData[lang]?.[difficulty]?.length &&
          <QuestionTitleBar
            title={lang === "NL" ? `Vraag ${currentQuestion + 1}` : `Question ${currentQuestion + 1}`}
            showFeedback={showFeedback}
            correct={answers[answers.length - 1]?.correct}
            onHomeButton={handleHome}
          />
        }
        {currentQuestion !== QuizData[lang]?.[difficulty]?.length &&
          <QuestionDisplay
            question={QuizData[lang]?.[difficulty]?.[currentQuestion]}
            onAnswer={onAnswer}
            answered={showFeedback}
            onNext={onNext}
            onRestart={resetQuiz}
            sessionID={sessionID}
            lang={lang}
            selectedIndex={selectedIndex}
            multimedia={Images[difficulty]?.[currentQuestion]}
          />
        }

        {currentQuestion === QuizData[lang]?.[difficulty]?.length &&
          <ResultDisplay
            lang={lang}
            difficulty={difficulty}
            answers={answers}
            changeDifficulty={() => handleDifficultyToggle()}
            onReturn={handleHome}
          />
        }
      </div>
    )
  }

  return (
    <div>
      <Background blurred={quizStarted} />
      {quizStarted && <Quiz />}
      {quizStarted || <MainMenu
        lang={lang}
        onSetDifficulty={handleSetDifficulty}
        onChangeLanguage={(val) => setLang(val)}
      />}
    </div >
  )
}

export default Main;
