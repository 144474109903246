import img1 from "../../static/images/1.webp"
import img2 from "../../static/images/2.webp"
import img3 from "../../static/images/3.jpg"
import img4 from "../../static/images/4.webp"
import img5 from "../../static/images/5.webp"
import img6 from "../../static/images/6.jpg"

import img7_1 from "../../static/images/7_kreeft.jpg"
import img7_2 from "../../static/images/7_pad.jpg"
import img7_3 from "../../static/images/7_zalm.jpg"
import img8_1 from "../../static/images/8.webp"
import img9 from "../../static/images/9.webp"
import img10 from "../../static/images/10.webp"

const Images = {

  HARD: {
    0: { src: img1, type: "image" },
    1: { src: img2, type: "image", },
    2: { src: img3, type: "image" },
    3: { src: img4, type: "image" },
    4: { src: img5, type: "image", credit: "Julie Hrudová", maxHeight: "700px" },
    5: { src: img6, type: "image" },
    6: { src: [img7_1, img7_2, img7_3], type: "slideshow" },
    7: { src: img8_1, type: "image" },
    8: { src: img9, type: "image" },
    9: { src: img10, type: "image", credit: "Julie Hrudová", maxHeight: "750px" },
  }
}

export default Images;