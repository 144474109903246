import { useState, useEffect } from 'react'
import { merge } from 'lodash';

import ReactApexChart from 'react-apexcharts';
import { Grid, Box, Typography, Paper, Skeleton, Select, MenuItem } from "@mui/material"

import { BaseOptionChart } from '../charts';
import { getNumberTilesValues, getNumberTilesGraphs } from '../../util/analyticsApi';


const TileChart = ({ name, data, labels }) => {
  let chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [3, 2, 3] },
    toolbar: {
      show: false
    },
    legend: {
      show: false
    },
    grid: {
      show: false,
      padding: {
        left: -10,
        right: 0
      }
    },
    plotOptions: { bar: { columnWidth: '0%', borderRadius: 0 } },
    fill: { type: ['gradient', 'gradient', 'solid'] },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        show: false
      },
      lines: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: false
      },
      lines: {
        show: false
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        }
      }
    }
  });

  chartOptions.labels = labels ? labels : []

  const CHART_DATA = [
    {
      name: name,
      type: 'area',
      data: data
    },
  ];

  return (
    <ReactApexChart locale="en" type="line" series={CHART_DATA} options={chartOptions} height={100} />
  );
}

const DateSelect = ({ handleChange }) => {
  return (
    <Select
      value={0}
      label="Timeframe"
      onChange={handleChange}
      sx={{
        marginLeft: "auto"
      }}
    >
      <MenuItem value={0}>Vandaag</MenuItem>
      <MenuItem value={1}>Deze Week</MenuItem>
      <MenuItem value={2}>Deze Maand</MenuItem>
    </Select>
  )
}

const NumberTile = ({ value, title, chartName, chartData, chartLabels }) => {

  const percentage = chartData ? (((chartData[chartData?.length - 1] / chartData[0]) - 1) * 100).toFixed(1) : 0


  return (
    <Grid item md={4} sm={12} sx={{
      color: "#0E2150",
      fontFamily: "Space Grotesk",
    }}>
      <Paper sx={{
        padding: 0,
        background: "#2AEE7D",
        color: "#0E2150",
        borderRadius: "3px"
      }}>
        <Box sx={{ padding: 2, mb: 0, pb: 0 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography fontFamily={"Space Grotesk"} variant="h4" fontWeight={400}>
              {title}
            </Typography>
            {/* <DateSelect /> */}
          </div>
          <Grid container>
            <Grid item sm={6}>
              <Typography fontFamily={"Space Grotesk"} variant="body2" textAlign={"left"}>
                <b>{percentage > 0 && "+"} {percentage}% </b> sinds vorige week
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography fontFamily={"Space Grotesk"} variant="h2" textAlign={"right"}>
                {value !== undefined ? value : <Skeleton variant="circular" animation="wave" width={50} height={50} sx={{ display: "inline-block", }} />}

              </Typography>
            </Grid>

          </Grid>
        </Box>
        <div>
          {chartData && <TileChart data={chartData} name={chartName} labels={chartLabels} />}
          {chartData === undefined && <Skeleton sx={{ bgcolor: "" }} variant="rectangular" width={"100%"} height={100} animation="wave" />}
        </div>
      </Paper>
    </Grid >
  )
}

const REFRESH_RATE = 10000;

const NumberTiles = () => {

  const [valueData, setValueData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined)

  const fetchValues = () => {
    getNumberTilesValues().then((res) => {

      setValueData(res.data)
    })
  }

  const fetchGraphs = () => {
    getNumberTilesGraphs().then((res) => {
      setChartData(res.data)
    })
  }

  useEffect(() => {
    fetchValues()
    fetchGraphs();
    const interval = setInterval(() => {
      fetchValues()
      fetchGraphs();
    }, REFRESH_RATE);
    return () => clearInterval(interval);
  }, [])

  return (
    <Box sx={{ flexGrow: 1, background: "#0E2150", padding: 5 }}>
      <Grid container spacing={5}>
        <NumberTile
          value={valueData?.quizes_started}
          title="Sessies Gestart"
          chartData={chartData?.values?.quizes_started}
          chartLabels={chartData?.dates}
          chartName={"Quizes Started"}

        />
        <NumberTile
          value={valueData?.quizes_completed}
          title="Sessies Voltooid"
          chartData={chartData?.values?.quizes_completed}
          chartLabels={chartData?.dates}
          chartName={"Quizes Completed"}
        />

        <NumberTile value={valueData?.questions_answered}
          title="Vragen Ingevuld"
          chartData={chartData?.values?.questions_answered}
          chartLabels={chartData?.dates}
          chartName={"Questions Answered"}
        />
      </Grid>
    </Box>
  )
}

export default NumberTiles;