import Logo from "../../static/images/logo_v2.png"

const LoginHeader = () => {
  return (
    <div style={{
      background: "#0E2150",
      color: "#095283",
      padding: "30px",
      height: "auto"
    }}>
      <a>
        <img
          style={{
            display: "block",
            maxWidth: "1154px",
            margin: "0 auto",
            width: "100%"
          }}
          src={Logo}
          alt="Museum Het Grachtenhuis" />
      </a>
    </div>
  )
}

export default LoginHeader;