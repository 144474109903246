import { useSelector } from 'react-redux';

import LoginForm from "../../components/AnalyticsLogin/LoginForm";
import LoginHeader from "../../components/AnalyticsLogin/Header";

import Background from "../../static/images/analytics_background.png";

const AnalyticsLogin = () => {

  return (
    <div style={{
      height: "100vh",
      background: '#0E2150',
      backgroundImage: `url(${Background})`,
      backgroundColor: '#0E2150',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      backgroundSize: "100% 80%"
    }}>
      <LoginHeader />
      <div style={{
        marginTop: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <LoginForm />
      </div>

    </div>
  )
}

export default AnalyticsLogin;