import { SET_TOKEN, SET_ERROR } from '../types/actions';
import { logIn as loginAPI } from '../util/api';
import store from '../Store';

export const setToken = (token: any) => ({
    type: SET_TOKEN,
    payload: token
})

export const setError = (error: any) => ({
    type: SET_ERROR,
    payload: error
})

export const logIn = (username: string, password: string) => (dispatch: any) => {
    loginAPI(username, password).then((res: any) => {
        const token = res.data;
        store().persistor.purge();
        dispatch(setToken(token))
        window.location.reload();
    })
        .catch((e) => {
            dispatch(setError(e.response?.data?.detail))
        });
}

export const logOut = () => (dispatch: any) => {
    store().persistor.purge();
    window.location.href = "/insights"
    dispatch(setToken(null));
}
