import { SET_ERROR, CLEAR_ERROR } from "../types/actions"

const errorReducer = (state: any = { message: undefined }, action: any) => {
    switch (action.type) {
        case SET_ERROR:
            return { ...state, message: action.payload }

        case CLEAR_ERROR:
            return { ...state, message: undefined }
        default:
            return state;
    }
}

export default errorReducer;
