import axios from "axios";

export const logIn = async (username: string, password: string) => {

  const payload = new FormData();
  payload.append("username", username);
  payload.append("password", password);

  return await axios.post(
    `/api/user/login`,
    payload
  )
}


export const createSession = (language: string, difficulty: string) => {
  const payload = {
    language: language,
    difficulty: difficulty
  }
  return axios.post("/api/session/", payload);
}

export const postAnswer = (sessionID: string, questionID: number, answerID: number, correct: boolean) => {
  const payload = {
    question_id: questionID,
    answer_id: answerID,
    correct: correct
  }

  return axios.post(`/api/session/${sessionID}/`, payload);
}

export const finishSession = (sessionID: string) => {
  return axios.post(`/api/session/${sessionID}/finish`);
}