import { useEffect, useState } from "react"
import { merge } from 'lodash';
import { Box, Paper, Skeleton, Typography } from "@mui/material"
import ReactApexChart from 'react-apexcharts';

import { BaseOptionChart } from '../charts';
import { getQuestionCorrectData } from '../../util/analyticsApi';

const Chart = ({ chartData }) => {
  const series = [
    { name: "Makkelijk", data: chartData?.easy },
    { name: "Moeilijk", data: chartData?.hard }
  ]

  const options = merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['transparent']
    },
    colors: ["#2AEE7D", "#FE3E21", "rgb(255, 231, 0)"],
    xaxis: {
      categories: ['V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'V9', 'V10'],
    },
    yaxis: {
      title: {
        text: 'Fout beantwoord (%)'
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(1) + "%"
        },
      },
      min: 0,
      max: 100
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(1) + "% fout beantwoord"
        },

      },
      style: {
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
        backgroundColor: "black"
      },
      theme: "dark"
    }
  })

  return <ReactApexChart type="bar" series={series} options={options} height={400} />
}


const BarChart = () => {
  const [chartData, setChartData] = useState(undefined);

  const fetchData = () => {
    getQuestionCorrectData().then((res) => {
      if (JSON.stringify(res.data) !== JSON.stringify(chartData))
        setChartData(res.data)
    })
  }

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => {
      fetchData()
    }, 10000);
    return () => clearInterval(interval);
  }, [chartData])

  return (
    <Box sx={{ flexGrow: 1, padding: 5 }}>
      <Paper sx={{
        background: "#0E2150",
        color: "#2AEE7D",
        borderRadius: "3px"
      }}>
        <Box sx={{ padding: 2, mb: 2.2, pb: 0 }}>
          <Typography fontFamily={"Space Grotesk"} variant="h4" fontWeight={100}>
            Moeilijkheidsniveau Per Vraag
          </Typography>
        </Box>
        {chartData === undefined && <Skeleton animation="wave" height={400} width="100%" />}
        {chartData !== undefined && <Chart chartData={chartData} />}
      </Paper>
    </Box>
  )
}

export default BarChart;