import { Box, Button, Grid, Typography } from "@mui/material";
import SlideShow from "./SlideShow";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";

const Answer = ({ title, correct, index, onClick, answered, selected }) => {

  let background = "white"
  let border = "none"

  if (answered) {
    background = correct ? "#E0FAE7" : "#FFADAD"
    border = correct ? "solid 1px #09D840" : "solid 1px #E80000"
  }

  return (
    <Grid xs={12} item sx={{
      display: (!answered || (selected || correct)) ? "flex" : "none",
      background: background,
      border: border,
      color: "black",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      font: "normal normal normal calc(1.43 * 26px) Space Grotesk;",
      letterSpacing: "0px",
      borderRadius: "47px",
      pl: "15px",
      minHeight: "calc( 1.43 * 50px)",
      marginBottom: 5,
      cursor: "pointer",
    }}
      onClick={(e) => { onClick(e); }}
    >
      {selected && answered && <IconButton
        sx={{
          color: correct ? "#10D82B" : "#FF0606",
          fontSize: "calc(1.43 * 26px)",
        }}>
        {correct ? <CheckIcon fontSize="50px" /> : <CloseIcon fontSize="50px" />}
      </IconButton>}
      {`${String.fromCharCode(index + 65)}: ${title}`}
    </Grid>
  )
}

const Extra = ({ text }) => {
  return (
    <Grid xs={12} item sx={{
      color: "white",
      textAlign: "center",
      font: " normal normal normal calc(1.43 * 25px)/calc(1.43 * 30px) Poppins;",
      letterSpacing: "0px",
      padding: "30px",
      marginBottom: 5
    }}>
      {text}
    </Grid>
  )
}

const NextButton = ({ onClick, lang }) => {
  return (
    <Button sx={{
      color: "black",
      background: "white",
      position: "absolute",
      right: "100px",
      borderRadius: "47px",
      height: "calc(1.43 * 64px)",
      font: "normal normal normal calc(1.43 * 26px)/calc(1.43 * 88px) Poppins",
      width: "calc(1.43 * 298px)",
      textTransform: "None"
    }}
      onClick={onClick}
    // endIcon={<ArrowForwardIosIcon />}
    >
      {lang === "NL" ? "Volgende vraag >" : "Next question >"}
    </Button>
  )
}

const RestartButton = ({ onClick, lang }) => {
  return (
    <Button sx={{
      color: "white",
      background: "black",
      borderRadius: "47px",
      height: "calc(1.43 * 64px)",
      font: "normal normal normal calc(1.43 * 26px)/calc(1.43 * 88px) Poppins",
      width: "calc(1.43 * 298px)",
      textTransform: "None"
    }}
      onClick={onClick}
    >
      {lang === "NL" ? "Begin opnieuw" : "Restart"}
    </Button>
  )
}

function shuffle(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


const QuestionDisplay = ({ question, onAnswer, answered, onNext, onRestart, lang, multimedia, selectedIndex }) => {

  return (
    <Grid container justifyContent={"center"} sx={{
      alignItems: "center", display: "flex",
      flexDirection: "column"
    }}>

      <Grid xs={10} item>
        {multimedia &&
          <Box sx={{
            display: "flex",
            maxHeight: multimedia?.maxHeight ? multimedia?.maxHeight : `600px`,
            overflow: "hidden",
            mt: multimedia?.offsetY ? 5 : 0
          }}>
            <Grid xs={12} item sx={{
              background: "None",

              color: "white",
              textAlign: "center",
              font: "normal normal normal 26px/30px Space Grotesk;",
              letterSpacing: "0px",
              borderRadius: "47px",
              padding: "30px",

              overflow: "hidden",
              mt: multimedia.offsetY,

              marginBottom: 5
            }}>
              {multimedia?.type === "image" && <img width="100%" src={multimedia?.src} alt={question?.title} />}
              {multimedia?.type === "video" && <video src={multimedia?.src} loop autoPlay muted style={{ objectFit: "fill", width: "100%" }} />}
              {multimedia?.type === "slideshow" && <SlideShow images={multimedia?.src} interval={2500} />}

              {multimedia?.credit && <Box
                sx={{
                  mt: "10px",
                  font: "normal normal medium calc(1.43 * 25px)/calc(1.43 * 27px) Poppins;",
                  color: '#000000',
                  fontSize: "calc(1.43 * 25px)"
                }}
              >{lang === "NL" ? "Foto: " : "Photo: "}{multimedia?.credit} </Box>}
            </Grid>
          </Box>}


        <Grid xs={12} item sx={{
          background: "black",
          color: "white",
          textAlign: "center",
          font: "normal normal normal calc(1.43 * 25px)/calc(1.43 * 38px) Poppins;",
          letterSpacing: "0px",
          borderRadius: "47px",
          padding: "30px",
          marginBottom: 5
        }}>
          {`${question?.id + 1}. ${question?.title}`}
        </Grid>


        {(question?.answers).map(({ title, correct }, index) => {
          return (
            <Answer
              key={index}
              title={title}
              correct={correct}
              index={index}
              answered={answered}
              selected={index === selectedIndex}
              onClick={() => { onAnswer(correct, index) }} />
          )
        })}
        {answered && question?.extra !== "" && <Extra text={question?.extra} />}

        {answered && <NextButton onClick={onNext} lang={lang} />}
        {answered && <RestartButton onClick={onRestart} lang={lang} />}
      </Grid>
    </Grid >
  )
}

export default QuestionDisplay;