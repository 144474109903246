import { Box } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const QuestionTitleBar = ({ title, showFeedback, correct, onHomeButton, lang }) => {
  return (
    <Box
      sx={{
        font: " normal normal 600 calc(1.43 * 54px)/calc(1.43 * 81px) Poppins;",
        textAlign: "center",
        textTransform: "uppercase",
        textShadow: "0px 3px 6px #00000029",
        letterSpacing: "0px",
        color: "#FFFFFF",
        height: "190px",
        borderBottom: "10px solid",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        margin: 0,
        padding: 0
      }}
    >
      <IconButton sx={{
        position: "absolute",
        left: "20px",
        color: "white",
        fontSize: "calc(1.43 * 62px)"
      }}
        onClick={onHomeButton}
      >
        <HomeIcon fontSize="calc(1.43 * 62px)" />
      </IconButton>
      <div>{title}</div>
    </Box>
  )

}

export default QuestionTitleBar;