import { Button, Box, Grid } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ResultDisplay = ({ answers, lang, difficulty, changeDifficulty, onReturn }) => {

  const Title = () => {
    return (
      <Box
        sx={{
          font: "normal normal bold calc(1.43 * 54px)/calc(1.43 * 64px) Raleway",
          textAlign: "center",
          textTransform: "uppercase",
          textShadow: "0px 3px 6px #00000029",
          height: "190px",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        Quiz
      </Box>
    )
  }

  const SubTitle = () => {
    return (
      <Box
        sx={{
          font: "normal normal bold calc(1.43 * 40px)/calc(1.43 * 47px) Space Grotesk;",
          textAlign: "center",
          textTransform: "uppercase",
          height: "calc(1.43 * 80px)",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          color: "black",
          background: "white",
        }}
      >
        {lang === "NL" ? "Overzicht" : "Overview"}
      </Box>
    )
  }



  const CorrectCount = () => {
    const correctCount = answers.filter((a) => {
      return a?.correct
    }).length

    return (
      <Box
        sx={{
          font: "normal normal 600 calc(1.43 * 33px)/calc(1.43 * 50px) Poppins;",
          textAlign: "center",
          height: "30px",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          color: "white",
        }}
      >
        {lang === "NL" ?
          `Je had ${correctCount} van de ${answers.length} vragen goed.`
          : `You got ${correctCount} out of ${answers.length} questions right.`
        }
      </Box>
    )
  }

  const AnswerBall = ({ val, correct }) => {
    return (
      <Grid
        item
        xs={2.4}
        sx={{
          height: "calc(1.43 * 72px)",
          font: "normal normal bold 36px/42px Space Grotesk;",
          color: "black",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",

        }}
      >
        <div style={{
          width: "calc(1.43 * 72px)",
          height: "calc(1.43 * 72px)",
          background: correct ? "#E0FAE7" : "#FFADAD",
          borderRadius: "100%",
          border: "calc(1.43 * 4px) solid white",
          font: "normal normal 600 calc(1.43 * 36px)/calc(1.3 * 55px) Poppins;",
          color: "black",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center"
        }}>
          {val}
        </div>
      </Grid>
    )
  }

  const AnswerDisplay = () => {
    return (
      <Grid container
        sx={{
          display: "flex",
          rowGap: "30px",
          mt: 10
        }}
        justifyContent="center"
        paddingLeft={23}
        paddingRight={23}
        marginTop={5}
        spacing={0}
      // rowSpacing={12}
      >
        {answers.map((a, index) => {
          return <AnswerBall val={index + 1} correct={a?.correct} />
        })}
      </Grid>
    )
  }

  const ExtraText = () => {
    let diff = "moeilijke"
    if (lang === "NL") {
      diff = difficulty === "EASY" ? "moeilijke" : "makkelijke"
    } else {
      diff = difficulty === "EASY" ? "difficult" : "easy"
    }
    return (
      <Box
        sx={{
          font: "normal normal bold 33px/39px Space Grotesk;",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          color: "white",
        }}
      >
        {lang === "NL" ? `Probeer ook eens de ${diff} quiz:` : `Give the ${diff} quiz a try:`}
      </Box>
    )
  }

  const OtherQuizButton = () => {
    return (
      <Button sx={{
        marginTop: "60px",
        color: "black",
        background: "white",
        borderRadius: "34px",
        height: "64px",
        font: "normal normal bold 36px/42px Raleway",
        letterSpacing: "0px",
        width: "191px",
        height: "117px",
      }}
        onClick={changeDifficulty}
      >
        {difficulty === "EASY" ? (lang === "NL" ? "Moeilijk" : "Difficult") : (lang === "NL" ? "Makkelijk" : "Easy")}
      </Button>
    )
  }

  const BackButton = () => {
    return (
      <Button sx={{

        color: "white",
        background: "black",
        borderRadius: "47px",
        height: "calc(1.43 * 64px)",
        font: "normal normal normal calc(1.43 * 26px) Poppins;",
        // padding: "30px",
        textTransform: "None",
        width: "calc(1.43 * 374px)"
      }}
        onClick={onReturn}
      >
        {lang === "NL" ? "Terug naar beginscherm" : "Back to home screen"}
      </Button >
    )
  }

  return (
    <div>
      <Title />
      <SubTitle />
      <Grid container justifyContent={"center"} sx={{
        height: "calc(100vh - 190px - 80px)", alignItems: "center", display: "flex"
      }}>
        <Grid xs={12} item>
          <CorrectCount />
          <AnswerDisplay />
        </Grid>
        <Grid xs={6}  >
          <Grid container justifyContent="center">
            {/* <ExtraText /> */}
            {/* <OtherQuizButton /> */}

          </Grid>
          <Grid container justifyContent="center">
            <BackButton />
          </Grid>
        </Grid>
      </Grid>
    </div >
  )
}

export default ResultDisplay;