import { useEffect, useState } from "react"
import { merge } from 'lodash';
import { Box, Paper, Skeleton, Typography } from "@mui/material"
import ReactApexChart from 'react-apexcharts';

import { BaseOptionChart } from '../charts';
import { getPieChartData } from '../../util/analyticsApi';


const Chart = ({ chartData }) => {

  const data = [
    chartData?.easy?.nl,
    chartData?.easy?.en,
    chartData?.hard?.nl,
    chartData?.hard?.en
  ]

  const plotOptions = {
    pie: {
      donut: {
        dataLabels: {
          fontFamily: "Space Grotesk",
          style: { colors: ['red'] }
        },
        markers: {
          colors: ["red"]
        },
        labels: {
          show: true,
          color: "red",
          fontFamily: "Space Grotesk",
          style: {
            color: "red"
          },
          total: {
            show: true,
            label: 'Total',
            fontFamily: "Space Grotesk",
            color: "#2AEE7D"
          }
        }
      }
    }
  }

  const options = merge(BaseOptionChart(), {
    chart: {
      type: 'donut',
      fontFamily: "Space Grotesk",
    },
    plotOptions: plotOptions,
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
      style: { colors: ["white"], fontFamily: "Space Grotesk", }
    },
    theme: {
      monochrome: {
        enabled: false,
        color: "#2AEE7D",
        shadeTo: "dark",
        shadeIntensity: 0.65
      }
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      colors: ['white']
    },
    colors: ["#FE3E21", "#661740", "#2AEE7D", "rgb(255, 231, 0)"],
    labels: [
      "Makkelijk NL",
      "Makkelijk EN",
      "Moeilijk NL",
      "Moeilijk EN"
    ],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  })

  return <ReactApexChart type="donut" series={data} options={options} height={400} />
}

const PieChart = () => {

  const [chartData, setChartData] = useState(undefined);

  const fetchData = () => {
    getPieChartData().then((res) => {
      if (JSON.stringify(res.data) !== JSON.stringify(chartData))
        setChartData(res.data)
    })
  }

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => {
      fetchData()
    }, 10000);
    return () => clearInterval(interval);
  }, [chartData])

  return (
    <Box sx={{ flexGrow: 1, padding: 5 }}>
      <Paper sx={{
        background: "#0E2150",
        color: "#2AEE7D",
        borderRadius: "3px"
      }}>
        <Box sx={{ padding: 2, mb: 3, pb: 0 }}>
          <Typography fontFamily={"Space Grotesk"} variant="h4" fontWeight={100}>
            Speelsessie Verdeling
          </Typography>
        </Box>
        {chartData === undefined && <Skeleton variant="circular" animation="wave" height={400} width={400} />}
        {chartData !== undefined && <Chart chartData={chartData} />}
      </Paper>
    </Box>
  )
}

export default PieChart;