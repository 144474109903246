import { useState, useEffect } from "react";
import CrossfadeImage from "react-crossfade-image"

const SlideShow = ({ images, interval }) => {
  const [index, setIndex] = useState(0);


  useEffect(() => {
    const timer = setInterval(() => {
      setIndex(prevIdx => {
        if (prevIdx >= images.length - 1) {
          // Set Data
          return 0;
        }
        else {
          // Set Data
          return prevIdx + 1;
        }
      })
    }, interval);

    return () => window.clearTimeout(timer);
  }, []);

  return (
    <div style={{ height: "600px" }}>
      <img src={images[index]} alt="slideshow" style={{ width: "100%", }} />
    </div>
  )
}

export default SlideShow;