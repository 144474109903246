import { Grid } from "@mui/material";
import NumberTiles from "../../components/Analytics/NumberTiles"
import Header from "../../components/Analytics/Header"
import Charts from "../../components/Analytics/Charts"

const Analytics = () => {
  return (
    <Grid sx={{
      minHeight: "100vh",
      width: "100vw",
      background: "#EEE6D4"
    }}>
      <Header />
      <NumberTiles />
      <Charts />
    </Grid>
  )
}

export default Analytics;