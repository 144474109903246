import * as React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { CLEAR_ERROR } from '../types/actions';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const setError = () => ({
  type: CLEAR_ERROR,
  payload: null
})

const ErrorComponent = () => {

  const state = useSelector((state) => state)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setError())
  }

  const place = {

  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
      open={state?.error?.message !== undefined}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {"Error: " + state?.error?.message}
      </Alert>
    </Snackbar>
  )
}

export default ErrorComponent;