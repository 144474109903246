const SET_TOKEN = 'setToken';
const SET_LOADING = 'setLoading';
const SET_ERROR = 'setError';
const CLEAR_ERROR = 'clearError'

export {
  SET_TOKEN,
  SET_ERROR,
  SET_LOADING,
  CLEAR_ERROR
}
